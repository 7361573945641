import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Chart } from "react-google-charts";

const ReportChart = forwardRef(({ data }, ref) => {
  const chartContainerRef = useRef(null); // Assign ref to a div, not the Chart component

  const options = {
    title: "MCDA Result",
    chartArea: { width: "80%", height: "80%" }, // Adjust chart area to fit
    bars: "horizontal",
    bar: { groupWidth: "90%" },
    hAxis: {
      minValue: -20,
      maxValue: 20,
      ticks: [-20, -15, -10, -5, 0, 5, 10, 15, 20], // Grid interval of 5
      textStyle: {
        fontSize: 12,
        bold: true,
      },
    },
    vAxis: {
      // title: "Parameters",
      textStyle: {
        fontSize: 12,
        bold: true,
      },
      baseline: 0, // Aligns labels properly
      baselinePadding: 20, // Space between labels and Y-axis
    },
    legend: { position: "bottom", textStyle: { bold: true } }, // Bold legend text
    colors: ["#4CAF50", "#1E88E5", "#FB8C00"],
    width: "100%", // Responsive width
    height: "auto", // Adjust height dynamically
  };

  const exportChartImage = (callback) => {
    if (chartContainerRef.current) {
      const chartElement =
        chartContainerRef.current.getElementsByTagName("svg")[0];

      if (chartElement) {
        const svgData = new XMLSerializer().serializeToString(chartElement);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        const svgBlob = new Blob([svgData], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const imageUrl = canvas.toDataURL("image/png");
          //setChartImage(imageUrl);
          if (callback) callback(imageUrl);
          URL.revokeObjectURL(url);
        };

        img.src = url;
      }
    }
  };

  useImperativeHandle(ref, () => ({
    exportChartImage,
  }));

  return (
    <div
      ref={chartContainerRef}
      style={{ position: "absolute", left: "-9999px", width: "100%" }}
    >
      <Chart
        chartType="BarChart"
        width="100%"
        height="600px"
        data={data}
        options={options}
      />
    </div>
  );
});

export default ReportChart;
