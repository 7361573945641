import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SkeletonTheme baseColor="#919090" highlightColor="#dad3d3">
        {/* Skeleton Loader */}
        <Skeleton count={10} height={20} style={{ marginBottom: "5px" }} />
      </SkeletonTheme>

      {/* Overlay text in the middle */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
          color: "white",
          padding: "10px 20px",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "bold",
          textAlign: "center",
          zIndex: 1, // Ensures it's above the skeleton
        }}
      >
        Please wait...
      </div>
    </div>
  );
};

export default SkeletonLoader;
