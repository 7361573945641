import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./SuitabalityCalculation.css";
import {
  TextField,
  Button,
  MenuItem,
  Slider,
  Select,
  InputLabel,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
} from "@mui/material";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { getPointById, pointEditById } from "../../services/addPointService";
import { getAllFeatureOfLayerByLayerNameAndDistrict } from "../../services/FeatureInfoService";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import {
  addNewBusinessSenarioForWithMapPoint,
  downloadGossaryPdf,
  downloadReportData,
  getAllBusinessModel,
  getAllCtegory,
  getAllValueAddedProducts,
  getBusinessModelByValueAddedProducts,
  getExpectedLifeOfPlants,
  getRRRObjective,
  getTruckCapacity,
} from "../../services/analysisService";
import Loader from "../../external-library/loader/Loader";
import WeightAnalysis from "../weight-analisis/WeightAnalysis";
import { Material } from "../../external-library/material/Material";
import AlertSnakBar from "../dialogs/alert-message/AlertSnakBar";
import ResultTable from "../tables/suitablity-result-table/ResultTable";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { base64ToFile } from "../../utilities/calculationUtil";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f1eee3",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
// Custom Icon Components
const DumpsterFireIcon = ({ active, completed }) => (
  <i
    className="fa-solid fa-dumpster-fire"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);

const ResultView = ({ active, completed }) => (
  <i
    className="fa-solid fa-table"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);

const Mcda = ({ active, completed }) => (
  <i
    className="fa-solid fa-calculator"
    style={{
      fontSize: "1.2rem",
      color: active ? "orange" : completed ? "green" : "#003031",
    }}
  ></i>
);
const SuitabilityCalculation = ({ id, onCancel, mapInfo,isResetForm,setIsResetForm }) => {
  const userDetails = useSelector((state) => state.auth.user);
  const countryName = useSelector(
    (state) => state.SelectedCountrySlice.selectedCountry
  );
  const [aleartOpen, setAlertOpen] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [pointDetails, setPointDetails] = useState(null);
  const [districtDetails, setDistrictDetails] = useState({});
  const [category, setCategory] = useState([]);
  const [wasteTypeList, setWasteTypeList] = useState([]);
  const [wasteQuantity, setWasteQuantity] = useState(null);
  const [businessModel, setBusinessModel] = useState([]);
  const [truckCapacity, setTruckCapacity] = useState([]);
  const [businessModelByWasteType, setBusinessModelByWasteType] = useState([]);
  const [calculationDetails, setCalculationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valuAddedProduct, setValueAddedProduct] = useState([]);
  const [selectedWasteCategory, setSelectedWasteCategory] = useState(null);
  const [selectedValueAddedProduct, setSelectedValueAddedProduct] =
    useState(null);
  const [expectedLifeOfPlants, setExpectedLifeOfPlants] = useState([]);
  const [rrrObjectiveList, setRrrObjectiveList] = useState([]);
  const [selectedRrrObjectiveList, setSelectedRrrObjectiveList] = useState({});
  const [mcdaId, setMcdaId] = useState(null);
  const [selectedWasteType, setSelectedWasteType] = useState(null);
  const [chartImage, setChartImage] = useState(null);

  const formStep1 = useForm({
    defaultValues: {
      wtfName: "",
      district: "",
      businessScenarioName: null,
      wasteCategory: "",
      wasteType: "",
      wasteQuantity: null,
      rrrObjectiveId: "",
      wasteCollectionEfficiency: 50,
      valueAddedProduct: "",
      avgDistanceTraveled: null,
      truckCapacity: "",
      plantCapacityFactor: 80,
      expectedLifeOfPlant: "",
      scaleOfPlantValue: null,
      businessModels: "",
    },
  });

  const {
    handleSubmit: handleSubmitStep1,
    control: controlStep1,
    register: registerStep1,
    reset: resetStep1,
    setValue: setValue1,
    formState: {
      errors: errorsStep1,
      isDirty: isDirtyStep1,
      isSubmitting: isSubmittingStep1,
    },
    getValues: getValuesStep1,
  } = formStep1;

  useEffect(() => {
    getAllValuAddedProductList();
    getTruckCapacity().then((res) => {
      setTruckCapacity(res.data);
    });
    getAllBusinessModelList();
    getExpectedLifeOfPlants()
      .then((res) => {
        setExpectedLifeOfPlants(res.data);
      })
      .catch((error) => {
        setExpectedLifeOfPlants([]);
      });
    getRRRObjective()
      .then((res) => {
        setRrrObjectiveList(res.data);
      })
      .catch((error) => {
        setRrrObjectiveList([]);
      });
  }, []);

  useEffect(() => {
    if (id !== null) {
      setWasteQuantity(null);
      setSelectedValueAddedProduct(null);
      resetStep1({
        wtfName: "",
        district: "",
        businessScenarioName: null,
        wasteCategory: "",
        wasteType: "",
        wasteQuantity: null,
        rrrObjectiveId: "",
        wasteCollectionEfficiency: 50,
        valueAddedProduct: "",
        avgDistanceTraveled: null,
        truckCapacity: "",
        plantCapacityFactor: 80,
        expectedLifeOfPlant: "",
        scaleOfPlantValue: null,
        businessModels: "",
      });
    }
    setIsResetForm(false)
  }, [id,isResetForm]);

  useEffect(() => {
    if (
      selectedWasteCategory !== null &&
      selectedValueAddedProduct !== null &&
      selectedWasteType !== null &&
      businessModelByWasteType.length > 0
    ) {
      getBusinessModelByValueAddedProducts(
        selectedWasteCategory,
        selectedValueAddedProduct,
        businessModelByWasteType.map((md) => md.type),
        selectedWasteType
      )
        .then((res) => {
          if (!res?.data?.isMatch) {
            setAlertOpen({
              open: true,
              type: "warning",
              message: res?.data?.compostingRecommendation,
            });
          }
          setBusinessModel(res?.data?.matchedBusinessModels || []);
          setValue1(
            "businessModels",
            res?.data?.matchedBusinessModels?.map((md) => md.type)
          );
        })
        .catch((error) => {
          setAlertOpen({
            open: true,
            type: "error",
            message: error?.response?.data || error.message,
          });
          setBusinessModel([]);
          setValue1("businessModels", []);
        });
    }
  }, [
    selectedWasteCategory,
    selectedValueAddedProduct,
    businessModelByWasteType.length,
    selectedWasteType,
  ]);
  useEffect(() => {
    getPointDetails();
    getAllCategoryList();
    setActiveStep(0);
  }, [id, countryName]);

  useEffect(() => {
    setValue1("wtfName", pointDetails?.pointName);
    setValue1("district", pointDetails?.districtName);
    setValue1("wasteQuantity", wasteQuantity);
  }, [pointDetails, wasteQuantity]);

  const getPointDetails = () => {
    setIsLoading(true);
    getPointById(id)
      .then((res) => {
        setPointDetails(res.data);
        return res.data.districtName;
      })
      .then((district) => {
        fetchDistricts(district);
      })
      .catch((error) => {
        setPointDetails({
          districtName: null,
          mapPointArray: null,
          mapPointString: null,
          pointName: null,
        });
      });
  };

  const fetchDistricts = async (districtName) => {
    const layerName =
      countryName === "Ghana" ? "ghana_wasteprj" : "viet_diswaste_fin";
    await getAllFeatureOfLayerByLayerNameAndDistrict(layerName, districtName)
      .then((res) => {
        const format = new GeoJSON();
        const incomingCrs = res.data.crs?.properties?.name || "EPSG:4326"; // Defaulting to EPSG:4326 if no CRS is found

        // Read the features and reproject if needed
        const features = format.readFeatures(res.data, {
          dataProjection: incomingCrs, // Incoming CRS from the GeoJSON
          featureProjection: "EPSG:3857", // The projection you want to display the features in (for your map)
        });
        let properties = features[0].getProperties();
        setDistrictDetails(properties);
        setIsLoading(false);
      })
      .catch((error) => {
        setDistrictDetails({});
        setIsLoading(false);
      });
  };

  const getAllCategoryList = () => {
    getAllCtegory(countryName)
      .then((res) => {
        setCategory(res.data);
      })
      .catch(() => {
        setCategory([]);
      });
  };

  const getAllBusinessModelList = () => {
    getAllBusinessModel()
      .then((res) => {})
      .catch((error) => {});
  };
  const getAllValuAddedProductList = () => {
    getAllValueAddedProducts()
      .then((res) => {
        setValueAddedProduct(res.data);
      })
      .catch((error) => {
        setBusinessModel([]);
      });
  };

  let getWasteQuantityByWasteTypeName = (wasteType) => {
    let wasteAmount = Number(districtDetails[wasteType]) || 0;
    setWasteQuantity(wasteAmount);
  };

  const addNewBusinessSenarioForMapPoint = (formData) => {
    const converted = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (typeof value === "string" && !isNaN(value) && value.trim() !== "") {
          return [key, Number(value)];
        }
        if (Array.isArray(value)) {
          return [
            key,
            value.map((item) =>
              typeof item === "string" && !isNaN(item) ? Number(item) : item
            ),
          ];
        }
        return [key, value];
      })
    );
    converted.mapPointId = pointDetails.id;
    converted.countryName = countryName;
    addNewBusinessSenarioForWithMapPoint(converted)
      .then((response) => {
        setAlertOpen({
          open: true,
          type: "success",
          message: "Information added successfully",
        });
        setCalculationDetails(response.data);
        nextStep();
      })
      .catch((error) => {
        setAlertOpen({ open: true, type: "error", message: error.message });
      });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setMcdaId(null);
  };

  const nextStep = (data) => {
    setMcdaId(null);
    handleNext();
  };

  const downloadReport = async () => {
    let img = await downloadMapImage();
    setIsLoading(true);
    let obj = {
      mapPointId: pointDetails?.id,
      businessScenarioId: calculationDetails?.businessScenarioId,
    };
    await downloadReportFinal(obj, img);
  };

  const handleGotoHome = () => {
    if (mapInfo) {
      mapInfo.getView().animate({
        center:
          countryName === "Ghana"
            ? [-130360, 875164]
            : countryName === "Vietnam"
            ? [12062327, 1588655]
            : [0, 0], // The center of the map in [longitude, latitude] in EPSG:3857 projection
        zoom: 6, // The center of the map in [longitude, latitude] in EPSG:3857 projection
        zoom: 6,
        duration: 1000, // Animation duration in milliseconds
      });
    }
  };
  const downloadReportFinal = (obj, img) => {
    let paylod = { ...obj, mapImage: img, graphImage: chartImage };
    downloadReportData(paylod)
      .then((res) => {
        setIsLoading(false);
        // Creating a Blob and triggering download
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = "Report.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setAlertOpen({
          open: true,
          type: "success",
          message: "Report downloaded successfully!!",
        });
        // setMapImage(null);
        handleGotoHome();
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertOpen({
          open: true,
          type: "error",
          message: error.message,
        });
      });
  };

  const downloadPdf = () => {
    downloadGossaryPdf()
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = "glossary.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setAlertOpen({
          open: true,
          type: "success",
          message: "Glossary downloaded successfully!!",
        });
      })
      .catch(() => {
        setAlertOpen({
          open: true,
          type: "error",
          message: "Glossary downloaded failed",
        });
      });
  };

  const downloadMapImage = () => {
    return new Promise((resolve, reject) => {
      if (!mapInfo) return reject();

      // Get all vector layers
      const vectorLayers = mapInfo
        .getLayers()
        .getArray()
        .filter((layer) => layer instanceof VectorLayer);

      // Find the vector layer matching the provided name
      const targetLayer = vectorLayers.find(
        (layer) =>
          layer.get("title") === pointDetails?.pointName ||
          layer.get("name") === pointDetails?.pointName
      );

      if (!targetLayer) {
        console.error(`Vector layer "${pointDetails?.pointName}" not found.`);
        return;
      }

      // Hide all vector layers except the target one
      vectorLayers.forEach((layer) => {
        if (layer.get("name") === targetLayer.get("name")) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      });
      setTimeout(() => {
        const mapCanvas = document.createElement("canvas");
        const size = mapInfo.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext("2d");

        Array.prototype.forEach.call(
          mapInfo
            .getViewport()
            .querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity =
                canvas.parentNode.style.opacity || canvas.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);

              let matrix;
              const transform = canvas.style.transform;
              if (transform) {
                matrix = transform
                  .match(/^matrix\(([^\(]*)\)$/)[1]
                  .split(",")
                  .map(Number);
              } else {
                matrix = [
                  parseFloat(canvas.style.width) / canvas.width,
                  0,
                  0,
                  parseFloat(canvas.style.height) / canvas.height,
                  0,
                  0,
                ];
              }
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );

              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );

        // Convert to image and store in state
        const impMap = base64ToFile(mapCanvas.toDataURL(), "mapimage.png");
        // Restore original visibility of vector layers
        vectorLayers.forEach((layer, index) => layer.setVisible(true));
        return resolve(impMap);
      }, 1000);
    });
  };

  return (
    <div className="h-100">
      <Stepper
        activeStep={activeStep}
        connector={null}
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={DumpsterFireIcon}
            style={{ fontSize: ".1rem" }}
          >
            Waste Quantification
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconComponent={ResultView}
            style={{ fontSize: ".1rem" }}
          >
            MCDA Criteria Quantification
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={Mcda} style={{ fontSize: ".1rem" }}>
            MCDA Result View
          </StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <form
          onSubmit={handleSubmitStep1(addNewBusinessSenarioForMapPoint)}
          className="m-2"
        >
          <div className="container-fluid card p-3">
            <div className="row">
              <div className="col-md-4">
                <TextField
                  label="WTP Name"
                  {...registerStep1("wtfName", {
                    required: "WTP Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errorsStep1.wtfName}
                  helperText={errorsStep1.wtfName?.message}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="District Name"
                  {...registerStep1("district", {
                    required: "District Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errorsStep1.district}
                  helperText={errorsStep1.district?.message}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Business Scenario Name"
                  {...registerStep1("businessScenarioName", {
                    required: "Business Scenario Name is required",
                  })}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  error={!!errorsStep1.businessScenarioName}
                  helperText={errorsStep1.businessScenarioName?.message}
                />
              </div>
            </div>

            <h5 className="text-primary">Waste Quantification</h5>
            <div className="row">
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="waste-category-label">
                    Waste Category
                  </InputLabel>
                  <Controller
                    name="wasteCategory"
                    control={controlStep1}
                    rules={{ required: "Waste Category is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="waste-category-label"
                        label="Waste Category"
                        onChange={(e) => {
                          setSelectedWasteCategory(e.target.value);
                          const selectedWasteTypes = category.find(
                            (ct) => ct.name === e.target.value
                          )?.wasteTypes;
                          setWasteTypeList(selectedWasteTypes);
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.wasteCategory}
                      >
                        {category.map((cate) => (
                          <MenuItem key={cate.name} value={cate.name}>
                            {cate.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.wasteCategory && (
                    <small className="text-danger">
                      {errorsStep1.wasteCategory.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="waste-type-label">Waste Type</InputLabel>
                  <Controller
                    name="wasteType"
                    control={controlStep1}
                    rules={{ required: "Waste Type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="waste-type-label"
                        label="Waste Type"
                        onChange={(e) => {
                          setSelectedWasteType(e.target.value);
                          const wasteTypeName = e.target.value
                            .replace(/\s/g, "")
                            .toLowerCase();
                          getWasteQuantityByWasteTypeName(wasteTypeName);
                          const businessModelList = wasteTypeList.find(
                            (p) => p.name == e.target.value
                          )?.businessModels;
                          setBusinessModelByWasteType(businessModelList);
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.wasteType}
                      >
                        {wasteTypeList.map((type, ind) => (
                          <MenuItem key={ind} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.wasteType && (
                    <small className="text-danger">
                      {errorsStep1.wasteType.message}
                    </small>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="value-added-product-label">
                    Value Added Product
                  </InputLabel>
                  <Controller
                    name="valueAddedProduct"
                    control={controlStep1}
                    rules={{ required: "Value Added Product is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="value-added-product-label"
                        label="Value Added Product"
                        onChange={(e) => {
                          setSelectedValueAddedProduct(e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errorsStep1.valueAddedProduct}
                      >
                        {valuAddedProduct.map((product, pi) => (
                          <MenuItem
                            key={product.id}
                            value={product.name}
                            disabled={pi == 2 || pi == 3}
                          >
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.valueAddedProduct && (
                    <small className="text-danger">
                      {errorsStep1.valueAddedProduct.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="business-model-label" shrink>
                    Business Model
                  </InputLabel>
                  <Controller
                    name="businessModels"
                    control={controlStep1}
                    rules={{ required: "Business Model is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="business-model-label"
                        label="Business Model"
                        multiple // Enable multiple selection
                        value={field.value || []} // Ensure value is always an array
                        onChange={(event) => field.onChange(event.target.value)} // Update the value on change
                        error={!!errorsStep1.businessModelId}
                        renderValue={(selected) =>
                          businessModel
                            ?.filter((model) => selected.includes(model.type))
                            .map((model) => model.type)
                            .join(", ")
                        } // Render selected values as a comma-separated string
                      >
                        {businessModel?.map((model, bi) => (
                          <MenuItem
                            key={bi}
                            value={model.type}
                            disabled
                            sx={{
                              color: "gray", // Customize the text color
                              "&.Mui-disabled": {
                                color: "#000000", // Customize the color when disabled
                                backgroundColor: "transparent",
                                opacity: 1,
                              },
                            }}
                          >
                            {/* <Material.Checkbox
                              checked={field.value?.includes(model.type) || false}
                            /> */}
                            <Material.ListItemText primary={model.type} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.businessModelId && (
                    <small className="text-danger">
                      {errorsStep1.businessModelId.message}
                    </small>
                  )}
                </FormControl>
              </div>

              {/* RRR Objective Selection */}
              <div className="col-md-6 mt-3">
                <FormControl size="small" fullWidth>
                  <InputLabel id="rrr-objective-label">
                    RRR Objective
                  </InputLabel>
                  <Controller
                    name="rrrObjectiveId"
                    control={controlStep1}
                    rules={{ required: "RRR Objective is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="rrr-objective-label"
                        label="RRR Objective"
                        onChange={(e) => {
                          let rrrId = e.target.value;
                          let selectedRRR = rrrObjectiveList?.filter(
                            (ele) => ele.id == rrrId
                          )[0];
                          setSelectedRrrObjectiveList(selectedRRR);
                          field.onChange(e);
                        }}
                      >
                        {rrrObjectiveList.map((objective) => (
                          <MenuItem key={objective.id} value={objective.id}>
                            {objective.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.rrrObjectiveId && (
                    <small className="text-danger">
                      {errorsStep1.rrrObjectiveId.message}
                    </small>
                  )}
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <TextField
                  label="Waste Quantity Generated (Tons / year)"
                  type="number"
                  {...registerStep1("wasteQuantity", {
                    required: "Waste Quantity is required",
                  })}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // error={!!errorsStep1.wasteQuantity}
                  // helperText={errorsStep1.wasteQuantity?.message}
                />
              </div>
              <div className="col-md-6 mt-3">
                <small>Waste Collection Efficiency (%)</small>
                <Controller
                  name="wasteCollectionEfficiency"
                  control={controlStep1}
                  rules={{ required: "Efficiency is required" }}
                  render={({ field }) => (
                    <Slider
                      {...field}
                      value={field.value || 50} // Ensure the value is controlled by React Hook Form
                      onChange={(event, newValue) => field.onChange(newValue)}
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  )}
                />
                {errorsStep1.wasteCollectionEfficiency && (
                  <small className="text-danger">
                    {errorsStep1.wasteCollectionEfficiency.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Scale of Plant (Tons/day)"
                  size="small"
                  type="number"
                  fullWidth
                  {...registerStep1("scaleOfPlantValue", {
                    required: "Scale is required",
                  })}
                  error={!!errorsStep1.scaleOfPlantValue}
                  helperText={errorsStep1.scaleOfPlantValue?.message}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="Avg Distance Travelled (kms)"
                  size="small"
                  type="number"
                  fullWidth
                  {...registerStep1("avgDistanceTraveled", {
                    required: "Avg. Distance Traveled is required",
                  })}
                  error={!!errorsStep1.avgDistanceTraveled}
                  helperText={errorsStep1.avgDistanceTraveled?.message}
                />
              </div>
              <div className="col-md-6 mt-3">
                <Controller
                  name="truckCapacity"
                  control={controlStep1}
                  rules={{ required: "Truck Capacity is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl size="small" fullWidth error={!!error}>
                      <InputLabel id="truck-capacity-label">
                        Select Truck Capacity (MT)
                      </InputLabel>
                      <Select
                        {...field}
                        value={field.value || ""}
                        labelId="truck-capacity-label"
                        label="Select Truck Capacity (MT)"
                      >
                        {truckCapacity?.map((cp) => (
                          <MenuItem key={cp.id} value={cp.capacity}>
                            {cp.transportationType}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>

              <div className="col-md-6 mt-2 px-3">
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <span className="fw-bold" style={{ fontSize: "0.9rem" }}>
                        Plant Capacity Factor
                      </span>{" "}
                      <br />
                      <span className="fw-bold">What is it?</span>
                      <br />
                      <span className="text-justify">
                        Plant Capacity Factor (%) measures a power plant’s
                        efficiency, calculated as (Actual Energy Generated /
                        Maximum Possible Energy) x 100. It indicates
                        utilisation, with higher values showing greater
                        efficiency and reliability.
                      </span>{" "}
                      <br />
                      <span className="fw-bold"> Why does it matter?</span>
                      <br />
                      <span className="text-justify">
                        It ensures resource efficiency, minimises waste,
                        optimises energy use, and supports sustainable,
                        resilient production systems.
                      </span>
                    </React.Fragment>
                  }
                >
                  <span style={{ cursor: "pointer" }}>
                    Plant CapacityFactor (%){" "}
                    <i
                      className="fa-solid fa-circle-info  fa-lg  ms-3"
                      style={{ color: "#045372" }}
                    ></i>
                  </span>
                </HtmlTooltip>

                <Controller
                  name="plantCapacityFactor"
                  control={controlStep1}
                  rules={{ required: "Plant Capacity Factor is required" }}
                  defaultValue={80} // Set the default value here in Controller
                  render={({ field, fieldState }) => (
                    <Slider
                      {...field}
                      value={field.value || 80} // Ensure the value is controlled by React Hook Form
                      onChange={(event, newValue) => field.onChange(newValue)} // Update the value in React Hook Form
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                    />
                  )}
                />
              </div>

              <div className="col-md-6">
                <FormControl size="small" fullWidth>
                  <InputLabel id="expected-life-label">
                    Expected Operational Life of Plant (Years)
                  </InputLabel>
                  <Controller
                    name="expectedLifeOfPlant"
                    control={controlStep1}
                    rules={{
                      required: "Expected operational life is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="expected-life-label"
                        label="Expected Operational Life of Plant (Years)"
                        value={
                          expectedLifeOfPlants.some(
                            (opt) => opt.year === field.value
                          )
                            ? Number(field.value)
                            : ""
                        }
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!errorsStep1.expectedLifeOfPlant}
                      >
                        {expectedLifeOfPlants.map(({ id, year }) => (
                          <MenuItem key={id} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsStep1.expectedLifeOfPlant && (
                    <small className="text-danger">
                      {errorsStep1.expectedLifeOfPlant.message}
                    </small>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="row mt-3 align-items-center">
              {/* <Button onClick={downloadMapImage}>Download Map Image</Button> */}
              <div className="col-md-12 d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="ms-2"
                  startIcon={<i className="fa-solid fa-calculator fa-xs"></i>}
                >
                  Calculate Recovery Potential and Next
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}

      {activeStep === 1 && (
        <div className="py-2">
          <div className="card py-2  mt-2">
            <div style={{ width: "100%" }} className="px-1">
              <ResultTable calculationDetails={calculationDetails} />
            </div>
            <div className="d-flex justify-content-between px-1 mt-3">
              <Button
                variant="contained"
                color="error"
                type="button"
                onClick={handleBack}
                startIcon={<i className="fa-solid fa-backward-step fa-xs"></i>}
              >
                Previous
              </Button>
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  onClick={() => downloadPdf()}
                  className="ms-2"
                  //disabled={handleDisableButtonForFirstStep}
                  startIcon={<SimCardDownloadOutlinedIcon />}
                >
                  Glossary
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  onClick={() => nextStep()}
                  className="ms-2"
                  //disabled={handleDisableButtonForFirstStep}
                  endIcon={<i className="fa-solid fa-forward-step fa-xs"></i>}
                >
                  Save Scenario & Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div className="py-2">
          <div className="card py-2  mt-2">
            <div className="row px-2">
              <div className="col-12">
                <WeightAnalysis
                  selectedRrrObjectiveList={selectedRrrObjectiveList}
                  detailsForMCDAApiCall={calculationDetails}
                  setMcdaId={setMcdaId}
                  setChartImage={setChartImage}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between px-1 mt-3">
              <Button
                variant="contained"
                color="error"
                type="button"
                onClick={handleBack}
                startIcon={<i className="fa-solid fa-backward-step fa-xs"></i>}
              >
                Previous
              </Button>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  //type="submit"
                  className="ms-2"
                  startIcon={<i className="fa-solid fa-download" />}
                  onClick={downloadReport}
                  hidden={mcdaId == null}
                  disabled={userDetails?.user?.roles === "Viewer"}
                >
                  Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader isLoading={isLoading} />
      <AlertSnakBar aleartOpen={aleartOpen} setAlertOpen={setAlertOpen} />
    </div>
  );
};

export default SuitabilityCalculation;
