import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ViewerNotification({
  openNotificationDialog,
  handleClose,
}) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderRadius: "12px",
          textAlign: "center",
        },
      }}
      open={openNotificationDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      {/* Close Button */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Image / Illustration */}
      <DialogTitle textAlign="center">
        <img
          src="https://cdn-icons-png.flaticon.com/512/1680/1680172.png"
          alt="Illustration"
          style={{ width: "80px", margin: "0 auto", display: "block" }}
        />
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent sx={{ textAlign: "center", px: 4 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Welcome to OrganicWaste DSS!
        </Typography>
        <Typography variant="body2" color="textSecondary">
        To view the MCDA result, you need to register yourself as a User!
        </Typography>
      </DialogContent>

      {/* Actions (Buttons) */}
      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        {/* <Button
          variant="outlined"
          onClick={handleClose}
          sx={{ borderRadius: "8px" }}
        >
          View Zaps
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          sx={{ borderRadius: "8px" }}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
