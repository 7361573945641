import React, { useEffect, useState, useCallback, useRef } from "react";
import "./WeightAnalysis.css";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import {
  getAllBusinessModel,
  mcdaCalculation,
} from "../../services/analysisService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import SkeletonLoader from "../../external-library/loader/SkeletonLoader";
import ViewerNotification from "../dialogs/viewer-notification-dialog/ViewerNotification";
import ReportChart from "../../external-library/charts/ReportChart";
import { base64ToFile } from "../../utilities/calculationUtil";

const technicalKeys = [
  { displayName: "ERP (MJ/day)", dataKey: "erpCalculationValue" },
  { displayName: "NRP (kg/day)", dataKey: "NRP" },
  { displayName: "Part Availability", dataKey: "partAvailability" },
  {
    displayName: "Skilled/Unskilled Labour Ratio",
    dataKey: "skilledUnskilledRatio",
  },
  { displayName: "Land Use(ha)", dataKey: "landUse" },
  { displayName: "Water Use(KLD)", dataKey: "waterUse" },
  {
    displayName: "Energy Use(KW / day)",
    dataKey: "energyUse",
  },
];

const environmentalKeys = [
  { displayName: "GHG Emission (Kg CO2 equiv/day)", dataKey: "ghgEmissions" },
  { displayName: "Noise Emission", dataKey: "noise" },
  { displayName: "Odor Emission", dataKey: "odour" },
];

const socialKeys = [
  { displayName: "Job Creation (people)", dataKey: "jobsCreated" },
  { displayName: "Occupational H&S", dataKey: "healthSafetyRiskFactor" },
  { displayName: "Social Acceptance", dataKey: "societalAcceptanceFactor" },
  { displayName: "Women & Youth Inclusion", dataKey: "womenYouthInclusion" },
];
const economicKeys = [
  { displayName: "ROI (%)", dataKey: "ROI" },
  { displayName: "BCR Ratio", dataKey: "BCR" },
];

const resultView = [
  { displayName: "Feasibility Index", dataKey: "fInd" },
  { displayName: "Rank", dataKey: "rank" },
];

const keysToInclude = [
  "erpCalculationValue",
  "NRP",
  "partAvailability",
  "skilledUnskilledRatio",
  "landUse",
  "waterUse",
  "energyUse",
  "jobsCreated",
  "healthSafetyRiskFactor",
  "societalAcceptanceFactor",
  "womenYouthInclusion",
  "ghgEmissions",
  "noise",
  "odour",
  // "NPV",
  "ROI",
  "BCR",
  "fInd",
  "rank",
];

const WeightAnalysis = ({
  selectedRrrObjectiveList,
  detailsForMCDAApiCall,
  setMcdaId,
  setChartImage,
}) => {
  const userDetails = useSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [mcdaPayload, setMcdaPayload] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [allBusinessModel, setAllBusinessModel] = useState([]);
  const [runLoader, setRunLoader] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getAllBusinessModel().then((res) => {
      setAllBusinessModel(res.data);
    });
    selectedRrrObjectiveList?.criteria?.forEach((ele) => {
      if (ele.name == "Environment") {
        setValue("environmentalDomain", ele.weight);
      } else if (ele.name == "Technical") {
        setValue("technicalDomain", ele.weight);
      } else if (ele.name == "Economic") {
        setValue("economicDomain", ele.weight);
      } else if (ele.name == "Social") {
        setValue("socialDomain", ele.weight);
      }
    });
    let obj = {
      businessScenarioId: detailsForMCDAApiCall?.businessScenarioId,
      rrrObjectiveId: detailsForMCDAApiCall?.rrrObjectiveId,
    };
    setMcdaPayload(obj);
  }, [selectedRrrObjectiveList, detailsForMCDAApiCall]);

  const getNamesByIds = (idList) => {
    return idList
      .map((id) => {
        const match = allBusinessModel.find((model) => model.id === id);
        return match ? match.type : null; // Return type if found, otherwise null
      })
      .filter(Boolean); // Remove null values
  };

  const onSubmit = (data) => {
    if (mcdaPayload !== null) {
      setRunLoader(true);
      mcdaCalculation(mcdaPayload)
        .then((res) => {
          setMcdaId(res?.data?.id);
          let dataForTable = createTableDataStructure(res?.data);
          let chartInfo = createDataForChart(dataForTable);
          setChartData(chartInfo);
          setTableData(dataForTable);
          let column = Object.keys(dataForTable[0]).filter(
            (ele) => ele !== "key"
          );
          setColumnNames(column);
          setRunLoader(false);
          if (userDetails?.user?.roles === "Viewer") {
            setOpenNotificationDialog(true);
          }
        })
        .catch((error) => {
          setRunLoader(false);
        });
    }
  };

  useEffect(() => {
    if (chartData.length > 0) {
      setTimeout(() => {
        handleExportClick();
      }, 2000);
    }
  }, [chartData]);
  const handleExportClick = () => {
    //if (chartRef.current) {
    chartRef.current.exportChartImage((imageUrl) => {
      // Convert Base64 to Blob
      const chartImageFile = base64ToFile(imageUrl, "chartimage.png");
      setChartImage(chartImageFile); // Store the image immediately when it's ready
    });
    //}
  };
  const createTableDataStructure = (data) => {
    const {
      businessModelIds,
      weightedErpValues,
      weightedNrpValues,
      weightedPartsAvailability,
      weightedLabourCostFactor,
      weightedLandUse,
      weightedWaterUse,
      weightedEnergyUse,
      weightedJobsCreated,
      weightedHealthSafetyRiskFactor,
      weightedSocietalAcceptanceFactor,
      weightedWomenYouthInclusion,
      weightedGHG,
      weightedNoise,
      weightedOdour,
      weightedROI,
      weightedBCR,
      closenessCoefficients,
      ranks,
    } = data;

    const dataMap = {
      erpCalculationValue: weightedErpValues,
      NRP: weightedNrpValues,
      partAvailability: weightedPartsAvailability,
      skilledUnskilledRatio: weightedLabourCostFactor,
      landUse: weightedLandUse,
      waterUse: weightedWaterUse,
      energyUse: weightedEnergyUse,
      jobsCreated: weightedJobsCreated,
      healthSafetyRiskFactor: weightedHealthSafetyRiskFactor,
      societalAcceptanceFactor: weightedSocietalAcceptanceFactor,
      womenYouthInclusion: weightedWomenYouthInclusion,
      ghgEmissions: weightedGHG,
      noise: weightedNoise,
      odour: weightedOdour,
      ROI: weightedROI,
      BCR: weightedBCR,
      fInd: closenessCoefficients,
      rank: ranks,
    };
    const expandValues = (values, length) => {
      if (values.length === 1) {
        return Array(length).fill(String(values[0]));
      }
      return values;
    };

    const dataStructure = keysToInclude.map((key) => {
      const obj = { key };
      const businessModelNameList = getNamesByIds(businessModelIds);
      const expandedValues = expandValues(
        dataMap[key] || [],
        businessModelNameList.length
      );

      businessModelNameList.forEach((name, index) => {
        obj[name] = expandedValues[index] ?? null;
      });
      return obj;
    });

    return dataStructure;
  };
  

  const createDataForChart = (data) => {
    const keyMapping = {
      BCR: "BCR",
      ROI: "ROI",
      fInd: "WI",
      societalAcceptanceFactor: "SA",
      healthSafetyRiskFactor: "OHS",
      jobsCreated: "JC",
      odour: "Odour",
      noise: "Noise",
      ghgEmissions: "Net GHG",
      energyUse: "EU",
      waterUse: "WU",
      landUse: "LU",
      skilledUnskilledRatio: "SUR",
      partAvailability: "PA",
      erpCalculationValue: "ERP",
      NRP: "NRP",
    };
    // Dynamically extract headers
    const headers = [
      "Parameters",
      ...Object.keys(data[0]).filter((k) => k !== "key"),
      { role: "style" },
    ];

    // Convert data to array format
    const result = [
      headers,
      ...data.map((obj) => [
        keyMapping[obj.key],
        ...headers.slice(1).map((h) => obj[h] ?? null),
      ]),
    ];

    let filteredData = result.filter(
      (row) => row[0] !== null && row[0] !== undefined
    );
    filteredData = filteredData.filter(
      (row) => row[1] !== null && row[1] !== undefined
    );
    // Insert black line row after each row
    let finalData = [];
    filteredData.forEach((row) => {
      finalData.push(row); // Add original row

      // Create and add a separator row
      const separatorRow = new Array(row.length).fill(null);
      separatorRow[0] = ""; // First column empty
      separatorRow[separatorRow.length - 1] = "color: black; stroke-width: 20"; // Style applied in last column
      finalData.push(separatorRow);
    });
    return finalData;
  };

  const fixedTableData = (value, dataKey) => {
    return dataKey == "rank" ? value : Number(value).toFixed(2);
  };

  const renderSection = (title, keys) => {
    return (
      <>
        <TableRow>
          <TableCell
            colSpan={columnNames.length + 1}
            align="left"
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: "#d1dee2",
              color: "#003031",
              border: "2px solid #070750",
            }}
          >
            {title}
          </TableCell>
        </TableRow>
        {keys.map(({ displayName, dataKey }, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
            >
              {displayName}
            </TableCell>
            {columnNames.map((col, colIndex) => (
              <TableCell
                key={colIndex}
                align="center"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: 500,
                }}
              >
                {tableData.find((row) => row.key === dataKey)?.[col] !== null
                  ? fixedTableData(
                      tableData.find((row) => row.key === dataKey)?.[col],
                      dataKey
                    )
                  : "--"}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tree-view mt-6 w-100 bg-light"
        style={{
          // overflowY: "auto",

          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          borderRadius: 5,
        }}
      >
        <div
          className="fw-bold p-3 mb-3 d-flex justify-content-center"
          style={{ backgroundColor: "#d1dee2" }}
        >
          Weightage Share of Domains
        </div>
        <div className="row p-3">
          <div className="col-6 fw-bold">Technical</div>
          <div className="col-6">
            <Controller
              name="technicalDomain"
              control={control}
              rules={{ required: "Technical Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value || ""}
                  label="Technical Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.technicalDomain && (
              <small className="text-danger">
                {errors.technicalDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 mt-3 fw-bold">Environmental</div>
          <div className="col-6 mt-3">
            <Controller
              name="environmentalDomain"
              control={control}
              rules={{ required: "Environmental Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value || ""}
                  label="Environmental Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.environmentalDomain && (
              <small className="text-danger">
                {errors.environmentalDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 fw-bold mt-3">Economic</div>
          <div className="col-6 mt-3">
            <Controller
              name="economicDomain"
              control={control}
              rules={{ required: "Economic Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value || ""}
                  label="Economic Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.economicDomain && (
              <small className="text-danger">
                {errors.economicDomain.message}
              </small>
            )}
          </div>

          <div className="col-6 fw-bold mt-3">Social</div>
          <div className="col-6 mt-3">
            <Controller
              name="socialDomain"
              control={control}
              rules={{ required: "Social Domain is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value || ""}
                  label="Social Domain (%)"
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.socialDomain && (
              <small className="text-danger">
                {errors.socialDomain.message}
              </small>
            )}
          </div>
          <div className="col-12 mt-3">
            <Button
              variant="contained"
              color="success"
              type="submit"
              className=" float-end mt-3"
              startIcon={<i className="fa-regular fa-floppy-disk fa-xs"></i>}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
      {runLoader ? (
        <Paper className="mt-2 p-2" elevation={3}>
          <SkeletonLoader />
        </Paper>
      ) : (
        <>
          {tableData.length > 0 && (
            <TableContainer
              component={Paper}
              className="mt-3"
              style={{
                filter:
                  userDetails?.user?.roles === "Viewer" ? "blur(5px)" : "",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow
                    style={{
                      borderLeft: "2px solid #1F3F49",
                      borderRight: "2px solid #1F3F49",
                      borderBottom: "2px solid #9ddef1",
                    }}
                  >
                    <TableCell
                      colSpan={columnNames.length + 1}
                      align="center"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        backgroundColor: "#1F3F49",
                      }}
                      className="text-light py-3"
                    >
                      Aggregated Scores Comparison
                    </TableCell>
                  </TableRow>
                  <TableRow
                    className="bg-primary"
                    style={{ border: "2px solid #1F3F49" }}
                  >
                    <TableCell
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        backgroundColor: "#1F3F49",
                      }}
                      align="left"
                      className="text-light"
                    >
                      Key
                    </TableCell>
                    {columnNames.map((col, index) => (
                      <TableCell
                        key={index}
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          backgroundColor: "#1F3F49",
                        }}
                        align="center"
                        className="text-light"
                      >
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderSection("Technical", technicalKeys)}
                  {renderSection("Environmental", environmentalKeys)}
                  {renderSection("Social", socialKeys)}
                  {renderSection("Economic", economicKeys)}
                  {renderSection("MCDA Result", resultView)}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <ViewerNotification
            openNotificationDialog={openNotificationDialog}
            handleClose={() => setOpenNotificationDialog(false)}
          />
          <div>
            <ReportChart ref={chartRef} data={chartData} />
          </div>
        </>
      )}
    </>
  );
};

export default WeightAnalysis;
