import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
const allKeyList = [
  "erpOrNrpValues",
  "ghgValues",
  "bcrValues",
  "roiValues",
  "noise",
  "odour",
  "jobsCreated",
  "healthSafetyRiskFactor",
  "landUse",
  "energyUse",
  "waterUse",
  "societalAcceptanceFactor",
  "womenYouthInclusion",
  "skilledUnskilledRatio",
  "partAvailability",
  "nrp"
];
const technicalKeys = [
  { displayName: "ERP (KWh/day)", dataKey: "erpOrNrpValues" },
  { displayName: "NRP (kg/day)", dataKey: "nrp" },
  { displayName: "Part Availability", dataKey: "partAvailability" },
  {
    displayName: "Skilled/Unskilled Labour Ratio",
    dataKey: "skilledUnskilledRatio",
  },
  { displayName: "Land Use(ha)", dataKey: "landUse" },
  { displayName: "Water Use(KLD)", dataKey: "waterUse" },
  {
    displayName: "Energy Use(KW / day)",
    dataKey: "energyUse",
  },
];

const environmentalKeys = [
  { displayName: "GHG Emission (Kg CO2 equiv/day)", dataKey: "ghgValues" },
  { displayName: "Noise Emission", dataKey: "noise" },
  { displayName: "Odor Emission", dataKey: "odour" },
];

const socialKeys = [
  { displayName: "Job Creation (people)", dataKey: "jobsCreated" },
  { displayName: "Occupational H&S", dataKey: "healthSafetyRiskFactor" },
  { displayName: "Social Acceptance", dataKey: "societalAcceptanceFactor" },
  { displayName: "Women & Youth Inclusion", dataKey: "womenYouthInclusion" },
];
const economicKeys = [
  { displayName: "ROI (%)", dataKey: "roiValues" },
  { displayName: "BCR", dataKey: "bcrValues" },
];

const ResultTable = ({ calculationDetails }) => {
  const [columnNames, setColumnNames] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let dataForTable = createTableStructureData(calculationDetails);
    setTableData(dataForTable);
  }, [calculationDetails]);


  const createTableStructureData = (calculationRes) => {
    let data={...calculationRes};
    let techData=data.businessModel.map(el=>{
      return data?.technologyDataDtos?.filter(ele=>ele?.businessModel==el)[0]
  });
  const result = Object.keys(techData[0]).reduce((acc, key) => {
    // If the key exists in all data items, create the array of values for it
    acc[key] = techData.map(item => item[key]);
    return acc;
  }, {});
  const result2=Object.keys(data.businessModelTechicalGroup[0]).reduce((acc, key) => {
    // If the key exists in all data items, create the array of values for it
    acc[key] = data.businessModelTechicalGroup.map(item => item[key]);
    return acc;
  }, {});
  delete data?.technologyDataDtos;
  delete data?.businessModelTechicalGroup
  delete result?.businessModel
  delete result2?.businessModel
  let newStructure={...data,...result,...result2}
  let structireData=allKeyList.map(ele=>{
        let obj ={key:ele}
        newStructure?.businessModel?.forEach((b,i)=>{
           let value = newStructure[ele]?newStructure[ele]:[];
           obj[b]=value[i]
        })
        return obj;
    });
    const keyList=Object.keys(structireData[0]).filter(ele=>ele!="key")
    setColumnNames(keyList)
    return structireData
  };

  const renderBcrValue = (value) => {
    return  Number(value).toFixed(2);
  };
  const renderSection = (title, keys) => {
    return (
      <>
        <TableRow>
          <TableCell
            colSpan={columnNames.length + 1}
            align="left"
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: "#d1dee2",
              color: "#003031",
              border: "2px solid #070750",
            }}
          >
            {title}
          </TableCell>
        </TableRow>
        {keys.map(({ displayName, dataKey }, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
            >
              {displayName}
            </TableCell>
            {columnNames.map((col, colIndex) => (
              <TableCell
                key={colIndex}
                align="center"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: 500,
                }}
              >
                {tableData.find((row) => row.key === dataKey)?.[col] !==
                undefined
                  ? renderBcrValue(
                      tableData.find((row) => row.key === dataKey)?.[col]
                    )
                  : "--"}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow
            className="bg-primary"
            style={{ border: "2px solid #1F3F49" }}
          >
            <TableCell
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                backgroundColor: "#1F3F49",
              }}
              align="left"
              className="text-light"
            >
              Key
            </TableCell>
            {columnNames.map((col, index) => (
              <TableCell
                key={index}
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  backgroundColor: "#1F3F49",
                }}
                align="center"
                className="text-light"
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderSection("Technical", technicalKeys)}
          {renderSection("Environmental", environmentalKeys)}
          {renderSection("Social", socialKeys)}
          {renderSection("Economic", economicKeys)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultTable;
