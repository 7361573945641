import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import MapchangerSlice from "./slice/MapchangerSlice";
import MeasurmentchangerSlice from "./slice/MeasurmentchangerSlice";
import MeasurmentvalueSlice from "./slice/MeasurmentvalueSlice";
import LayerChangerSlice from "./slice/LayerChangerSlice";
import ThemeChangeSlice from "./slice/ThemeChangeSlice";
import AuthReducer from "../authentication/AuthReducer";
import AddedPointSlice from "./slice/AddedPointSlice";
import SiteSuitablitySlice from "./slice/SiteSuitablitySlice";
import SelectedCountrySlice from "./slice/SelectedCountrySlice"


const persistConfig = {
    key: 'main-root',
    storage: storage,
    whitelist: ['auth'], // reducer(s) to persist
};

const reducer = combineReducers({
    auth: AuthReducer,
    MapchangerSlice,
    MeasurmentchangerSlice,
    MeasurmentvalueSlice,
    LayerChangerSlice,
    ThemeChangeSlice,
    AddedPointSlice,
    SiteSuitablitySlice,
    SelectedCountrySlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export const persistor = persistStore(store);