const base64ToFile = (base64String, fileName) => {
  const byteCharacters = atob(base64String.split(",")[1]); // Decode Base64
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new File([byteArray], fileName, { type: "image/png" }); // Change MIME type if needed

  return file;
};
export {base64ToFile}
