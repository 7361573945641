import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCountry: ""
}

const selectedCountryChange = createSlice({
    name: "selectedcountry",
    initialState,
    reducers: {
        selectedCountryChangingAction: (state = initialState, action) => {
            const selectedCountryChangingState = {
                selectedCountry: action.payload,
            };
            return selectedCountryChangingState;
        }
    },
})

export const { selectedCountryChangingAction } = selectedCountryChange.actions;
export default selectedCountryChange.reducer;