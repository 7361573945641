import axios from "axios";
import { store } from "../../store/store";
import { logoutSuccess } from "../../authentication/AuthActions";

// Create an Axios instance
const axiosInstance = axios.create({});

// Request Interceptor for Token Handling
axiosInstance.interceptors.request.use((config) => {
  const state = store.getState(); // Access entire Redux state
  const token = state.auth?.user?.token; // Access token from auth state

  if (token && !config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)) {
    config.headers.Authorization = `Bearer ${token}`;
  } else if (config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)) {
    delete config.headers.Authorization;
  }
  return config;
});

// Response Interceptor for Error Handling
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Dispatch logout and clear auth state
        store.dispatch(logoutSuccess());
      }
    } else if (error.request) {
      //console.error("No response received:", error.request);
    } else {
      //console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
